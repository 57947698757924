<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <chemical-latex content="^{238}_{92}U" />
        decays via alpha emission with a half-life of
        <stemble-latex content="$4.46\times 10^9$" />
        years. A 1.00 mole sample of
        <chemical-latex content="^{238}_{92}U" />
        has an initial decay rate of
        <stemble-latex content="$4.28\times 10^6$" />
        disintegrations/second (dps). What is the decay rate of
        <chemical-latex content="^{238}_{92}U" />
        (in dps) after
        <number-value :value="time" />
        billion years?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Rate}:$"
        append-text="$\text{dps}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220S4Q9',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
  },
};
</script>
